.tutors {
  margin-top: -156px;
  padding-top: 0px;
}
a:hover,
a {
  text-decoration: none;
}
.tutors .col-md-3 {
  padding-left: 0px;
  padding-right: 0px;
}
.tutors .icon-box {
  /*  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
*/
  padding: 20px;
  /*  transition: all ease-in-out 0.4s;
  background: #fff;*/
  width: 100%;
  border-right: 1px solid #e0f7ff;
  border-top: 1px solid #e0f7ff;
}
/*.tutors .icon-box:nth-child(4){
  border-right: 0px solid #E0F7FF;
}*/
.tutors .row div:nth-child(4) .icon-box {
  border-right: 0px solid #e0f7ff;
  border-top: 0px solid #e0f7ff;
}
.tutors .row div:nth-child(4n + 1) .icon-box {
  border-top: 0px solid #e0f7ff;
}
.tutors .row {
  background-color: #fff;
  filter: drop-shadow(0px 30px 60px rgba(9, 40, 108, 0.1));
  border-radius: 20px;
}
.tutors .icon-box .icon {
  margin-bottom: 10px;
  /*  width: 72px;
  height: 72px;
  box-shadow: 0px 20px 40px rgba(9, 40, 108, 0.1);
border-radius: 100px;
text-align: center;
    line-height: 72px;*/
}

.tutors .icon-box h4 {
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #1c2c40;
  /* margin-bottom: 20px; */
}
.tutors .icon-box h4 a {
  color: #1c2c40;
  text-decoration: none;
}
.tutors .icon-box p {
  line-height: 17px;
  font-size: 14px;
  margin-bottom: 0;
  color: #4f5b6a;
  font-weight: normal;
}
.tutors .icon-box .count-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tutors .icon-box .count-area i {
  font-size: 40px;
  color: #b2c5dc;
  line-height: 40px;
}
@media (max-width: 576px) {
  .tutors {
    margin-top: 10px;
  }
}
