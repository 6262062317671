.profile_img{
    border-radius: 50%;
    height: 100px;
    width:100px;
}
.profile_input{
    display: none;
}
.icon{
    background-color: white;
}
.document_input{
    display: none;
}
.upload_file_container{
    padding:10px;
    display: flex;
    border: 2px dashed black;
    align-items: center;
    justify-content: center;
}
.reset_button_container{
    display: flex;
    align-items: end;
    justify-content: center;
}