
.newsletter-inner {
  padding: 70px 114px;
  background: url("../../assets/img/12.svg") no-repeat #FFE289;
 
  background-size: cover;
  background-position: bottom right;  text-align: left;
  font-size: 15px;
  border-radius: 20px;
  color: #444444;
  margin-bottom: 136px;
}

.newsletter-inner h4 {
  font-size: 40px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 54px;
  font-weight: bold;
  color: #1C2C40;
}
.newsletter-inner  form {
  margin-top: 30px;
  background: #fff;
  padding: 10px 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 10px 50px rgba(9, 40, 108, 0.05);
  text-align: left;
}
.newsletter-inner form input[type=email] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}
.newsletter-inner form .btn.btn-primary {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  background: #09286C;
  color: #fff;
  transition: 0.3s;
  border-radius: 0px 8px 8px 0px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #FFFFFF;
}
.newsletter-inner  form .btn.btn-primary:hover {
  background: #209dd8;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #000;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}
