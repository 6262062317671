.c_white{
    color:white !important
}
.c_grey{
    color:grey
}
.detail_navbar{
    border-radius: 8px;
}
.custom_loader{
    height: 50vh;
}