.checkout {
  min-height: 30rem;
}
.bagde {
  border: 0.5px solid grey;
}
.name {
  font-size: 1.5rem;
  font-weight: bolder;
}
.image {
  height: 50px;
  width: 50px;
  border: 1px solid black;
}
.bagde-container {
  margin-top: -10px;
}
