.about{background-color: #FFEEBC; padding: 60px 0;}
.about.why-us{ margin: 60px 0;}
.about.why-us  h3 {
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: #1C2C40;
}
.about.why-us   .accordion-list{
  padding-left: 0;
}
.about.why-us   p {
  font-size: 15px;
  color: #4F5B6A;
  line-height: 30px;
}
.about.why-us   ul {
  list-style: none;
  padding: 0;
}
.about.why-us .bg.img{
  transform: scale(1.4);
}
.about.why-us .bg.img {
  transform: scale(1.4);
}
/*.about .content ul li {
  padding-left: 28px;
  position: relative;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}*/
.about p:last-child {
  margin-bottom: 0;
}
.about .btn-learn-more {
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.3px;
  display: inline-block;
  padding: 12px 32px;
  background: #09286C;
  box-shadow: 8px 15px 30px rgba(9, 40, 108, 0.25);
  border-radius: 6px;
  transition: 0.3s;
  line-height: 1;
  color: #FFFFFF;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  width: max-content;
}
.about  .btn-learn-more:hover {
  background: #09286C;
  color: #fff;
  text-decoration: none;
}