.btn-warning,
.btn-success,
.btn-danger {
  color: white;
  pointer-events: none;
}
.tabs_section {
  min-height: 50vh;
}
.nav-link.active {
  border-bottom: 1px solid #dee2e6 !important;
}
.nav-link {
  cursor: pointer;
}
.profile_pic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
}
