.navbar-light .navbar-nav .nav-link {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #47b2e4;
}
.navbar .nav-link.getstarted,
.navbar .nav-link.getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  border: 0;
  font-weight: 500;
  background-color: #09286c;
}

#loginPopup .modal-header {
  justify-content: center;
  border: 0;
}
#loginPopup .modal-body .icon-box {
  text-align: center;
}
.img-fluid {
  max-width: 100%;
  height: auto;
}
#loginPopup .modal-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}
#loginPopup .modal-body .icon-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1c2c40;
  margin-bottom: 0px;
}
#loginPopup .modal-body .icon-box h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #1c2c40;
}
#loginPopup .modal-footer {
  justify-content: center;
  border: 0;
  align-items: center;
}
#loginPopup .btn-close,
#loginForm .btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 100%;
  right: -12px;
  top: -12px;
  font-size: 12px;
  padding: 7px;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.15);
}

#loginForm .modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: none;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}

.drop-down-item {
  color:black !important
}

.forgot-link{
  color: blue;
}
.modal-content {
  padding:10px
}
header{
  padding-right: 0 !important;
}