.edit-btn{
  width: 100%;
  height: 45px;
  bottom: 0;
  border: 0;
  font-size: 15px;
  background: #09286c;
  color: #fff;
  transition: 0.3s;
  color: #ffffff;
}
.edit-btn:hover{
  background: #09286c;
}
.left-card{
    height: max-content;
    min-height: 450px;
}
.profile_pic{
    border:1px solid rgba(0,0,0,.125)
}
.edit_profile_icon{
    display: inline-block;
    background: white;
    width: 40px !important;
    height: 40px !important;
    padding: 5px;
    position: absolute;
    top: 144px !important;
    left: 56% !important;
    border-radius: 50%;
    text-align: center;
    border:1px solid rgba(0,0,0,.150);
    color:#09286c;
    font-size: 1.2rem;
}
/* edit profile icon hide it */
.edit_profile_icon label{
  margin-top: 0;
}
.edit_profile_icon input{
  display: none;
}
.display_none{
  display: none;
}
.text{
  display: inline;
  width: 100%;
}
  
.read-or-hide{
  color: #09286c;
  cursor: pointer;
  font-weight: bold;
}