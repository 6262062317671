.max-width {
  max-width: 100% !important;
}
.w-30 {
  width: 30px !important;
}
.w-23 {
  width: 23px !important;
}
#hero form input {
  border: 0;
  padding: 4px 8px;
  font-weight: normal;
  font-size: 15px;
  width: 100%;
  color: white;
}
.search-box {
  padding-right: 3rem !important;
}
.range-icon {
  margin-left: auto !important;
}
.range-select-box {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 104%;
  right: 0;
  background-color: white;
  border-radius: 0.5rem !important;
  padding: 1rem;
}
.language-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  left: 0;
  z-index: 99;
  background: white;
  height: 16rem;
  padding: 0.5rem;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
}
.time-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  right: 0;
  z-index: 99;
  background: white;
  min-width: 490px;
  height: auto;
  padding: 0.7rem;
  margin-top: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.no-data-container {
  text-align: center;
  font-size: 2rem;
}

/* Scroll Bar */
.language-select-box ::-webkit-scrollbar {
  width: 5px;
}
.language-select-box ::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-left: 1rem;
}
.language-select-box ::-webkit-scrollbar-thumb {
  background: #1a2e9e;
  border-radius: 1rem;
}
.language-select-box ::-webkit-scrollbar-thumb:hover {
  background: #1a2e9e;
}
.time-select-boxes {
  padding: 0.7rem;
  text-align: center;
  border: 1px solid rgb(196, 196, 196);
}
.time-select-boxes:hover {
  background-color: #1a2e9e;
  color: white;
}
/* classes to change background of time and day slots */
.active-time-box {
  background-color: #1a2e9e;
  color: white;
}
.active-day-box {
  background-color: #1a2e9e;
  color: white !important;
}
/**
 * Main wrapper
 */
.select-search {
  width: 300px;
  position: relative;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
}

.select-search *,
.select-search *::after,
.select-search *::before {
  box-sizing: inherit;
}

/**
* Value wrapper
*/
.select-search__value {
  position: relative;
  z-index: 1;
}

.select-search__value::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: calc(50% - 9px);
  right: 19px;
  width: 11px;
  height: 11px;
}

/**
* Input
*/
.select-search__input {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: #fff;
  border: 1px solid transparent;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 36px;
  -webkit-appearance: none;
}

.select-search__input::-webkit-search-decoration,
.select-search__input::-webkit-search-cancel-button,
.select-search__input::-webkit-search-results-button,
.select-search__input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search__input:not([readonly]):focus {
  cursor: initial;
}

/**
* Options wrapper
*/
.select-search__select {
  background: #fff;
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
}

/**
* Options
*/
.select-search__options {
  list-style: none;
  padding: 0;
}

/**
* Option row
*/
.select-search__row:not(:first-child) {
  border-top: 1px solid #eee;
}

/**
* Option
*/
.select-search__option,
.select-search__not-found {
  display: block;
  height: 36px;
  width: 100%;
  padding: 0 16px;
  background: #fff;
  border: none;
  outline: none;
  font-family: "Noto Sans", sans-serif;
  font-size: 14px;
  text-align: left;
  cursor: pointer;
}

.select-search--multiple .select-search__option {
  height: 48px;
}

.select-search__option.is-selected {
  background: #2fcc8b;
  color: #fff;
}

.select-search__option.is-highlighted,
.select-search__option:not(.is-selected):hover {
  background: rgba(47, 204, 139, 0.1);
}

.select-search__option.is-highlighted.is-selected,
.select-search__option.is-selected:hover {
  background: #2eb378;
  color: #fff;
}

/**
* Group
*/
.select-search__group-header {
  font-size: 10px;
  text-transform: uppercase;
  background: #eee;
  padding: 8px 16px;
}

/**
* States
*/
.select-search.is-disabled {
  opacity: 0.5;
}

.select-search.is-loading .select-search__value::after {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='50' height='50' viewBox='0 0 50 50'%3E%3Cpath fill='%232F2D37' d='M25,5A20.14,20.14,0,0,1,45,22.88a2.51,2.51,0,0,0,2.49,2.26h0A2.52,2.52,0,0,0,50,22.33a25.14,25.14,0,0,0-50,0,2.52,2.52,0,0,0,2.5,2.81h0A2.51,2.51,0,0,0,5,22.88,20.14,20.14,0,0,1,25,5Z'%3E%3CanimateTransform attributeName='transform' type='rotate' from='0 25 25' to='360 25 25' dur='0.6s' repeatCount='indefinite'/%3E%3C/path%3E%3C/svg%3E");
  background-size: 11px;
}

.select-search:not(.is-disabled) .select-search__input {
  cursor: pointer;
}

/**
* Modifiers
*/
.select-search--multiple {
  border-radius: 3px;
  overflow: hidden;
}

.select-search:not(.is-loading):not(.select-search--multiple)
  .select-search__value::after {
  transform: rotate(45deg);
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  pointer-events: none;
}

.select-search--multiple .select-search__input {
  cursor: initial;
}

.select-search--multiple .select-search__input {
  border-radius: 3px 3px 0 0;
}

.select-search--multiple:not(.select-search--search) .select-search__input {
  cursor: default;
}

.select-search:not(.select-search--multiple) .select-search__input:hover {
  border-color: #2fcc8b;
}

.select-search:not(.select-search--multiple) .select-search__select {
  position: absolute;
  z-index: 2;
  top: 44px;
  right: 0;
  left: 0;
  border-radius: 3px;
  overflow: auto;
  max-height: 360px;
}

.select-search--multiple .select-search__select {
  position: relative;
  overflow: auto;
  max-height: 260px;
  border-top: 1px solid #eee;
  border-radius: 0 0 3px 3px;
}

.select-search__not-found {
  height: auto;
  padding: 16px;
  text-align: center;
  color: #888;
}

.speciality {
  background: rgba(255, 255, 255, 0.5);
  -webkit-backdrop-filter: blur(28.082px);
  backdrop-filter: blur(28.082px);
  border-radius: 12px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #ffffff;
  border: 0px;
  padding: 14px 27px;
}
.speciality-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  z-index: 99;
  background: white;
  padding: 0.5rem;
  overflow-y: scroll;
  min-width: 18rem;
  height: 16rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
  padding: 19px;
}
/* Scroll Bar */
.speciality-select-box::-webkit-scrollbar {
  width: 5px;
}
.speciality-select-box::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-left: 1rem;
}
.speciality-select-box::-webkit-scrollbar-thumb {
  background: #1a2e9e;
  height: 1rem;
  border-radius: 1rem;
}
.speciality-select-box::-webkit-scrollbar-thumb:hover {
  background: #1a2e9e;
}
.options {
  background-color: #1a2e9e;
}

.teachesTo-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  z-index: 99;
  background: white;
  padding: 16px;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
}

.nativeSpeaker-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  z-index: 99;
  background: white;
  padding: 14px;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
}

.container-check {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-check input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 6px;
}

/* On mouse-over, add a grey background color */
.container-check:hover input ~ .checkmark {
  background-color: #09286C;
}

/* When the checkbox is checked, add a blue background */
.container-check input:checked ~ .checkmark {
  background-color: #09286C;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-check input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-check .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

#country_checkbox {
  width: 21px;
}
.country-select-box {
  position: absolute;
  border-radius: 0.5rem !important;
  top: 100%;
  left: 0;
  z-index: 99;
  background: white;
  height: 16rem;
  padding: 0.5rem;
  min-width: 17rem;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-top: 10px;
}
/* Scroll Bar */
.country-select-box ::-webkit-scrollbar {
  width: 5px;
}
.country-select-box ::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-left: 1rem;
}
.country-select-box ::-webkit-scrollbar-thumb {
  background: #1a2e9e;
  border-radius: 1rem;
}
.country-select-box ::-webkit-scrollbar-thumb:hover {
  background: #1a2e9e;
}
.result-card {
  box-shadow: 0px 15px 30px rgba(9, 40, 108, 0.05);
  border-radius: 8px;
  padding: 18px;
}
.readmore {
  text-decoration: none;
}
.c_pointer {
  cursor: pointer;
}
.p_none {
  pointer-events: none;
}
.m_7 {
  margin: 7px;
  width: auto !important;
  color:black
}
#morning {
  border-top-left-radius: 8px !important;
}
#late_afternoon {
  border-top-right-radius: 8px !important;
}
#evening {
  border-bottom-left-radius: 8px !important;
}
#late_night {
  border-bottom-right-radius: 8px !important;
}
#sun {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}
#sat {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}
.active_time{
  background-color: #1a2e9e;
  color:white;
  cursor: pointer;
}
.not_active{
  cursor: pointer;
}
.not_active:hover{
  background-color: #1a2e9e;
  color:white;
}

