.footer-top {
  padding: 60px 0 30px 0;
  background: #000;
}

.footer-top {
  padding: 60px 0 30px 0;
  background: #000;
}
.footer-top .footer-contact {
  margin-bottom: 30px;
}

.footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 38px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
}
.footer-top .footer-contact p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 40px;
  color: #d9dbe1;
  font-weight: 400;
}
.footer-top h4 {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  padding-bottom: 12px;
}
.footer-top .footer-links {
  margin-bottom: 30px;
}
.footer-top .social-links a i {
  font-size: 18px;
}
.footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}
.footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
.footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
.footer-top .footer-links ul a {
  color: #d9dbe1;
  transition: 0.3s;
  display: inline-block;
  line-height: 26px;
  font-size: 16px;
}
.footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #47b2e4;
}
.footer-top .social-links a {
  font-size: 22px;
  display: inline-block;
  background: #23252e;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 8px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
.footer-top .social-links a:hover {
  background: #209dd8;
  color: #fff;
  text-decoration: none;
}
.footer-top .app-icons a {
  display: block;
  margin-top: 20px;
}
.footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}
.copyright {
  float: left;
}
.credits {
  float: right;
  font-size: 13px;
}
#footer .credits a {
  transition: 0.3s;
}

@media (max-width: 768px) {
  .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }
  #footer .credits {
    padding-top: 4px;
  }
  #footer .footer-newsletter {
    display: none;
  }
  section {
    padding: 20px 0;
  }
}
