/**
* Template Name: Arsha - v4.7.0
* Template URL: https://bootstrapmade.com/arsha-free-bootstrap-html-template-corporate/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: "Inter", "Open Sans", sans-serif;
  color: #444444;
}

a {
  color: #47b2e4;
  text-decoration: none;
}

a:hover {
  color: #73c5eb;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Inter", sans-serif;
}
/*-- testimonial Section CSS --*/
#testimonial {
  padding: 30px 0px 20px;
}
#testimonial h2 {
  text-align: left;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
  letter-spacing: 0.2px;
  color: #1c2c40;
  display: flex;
  margin-bottom: 0px;
  padding-bottom: 0px;
}
#testimonial h2 img {
  margin-right: 12px;
  margin-top: -80px;
}
#testimonial .client-img {
  margin: 0px auto;
  position: absolute;
  right: 0px;
  top: 0px;
}
#testimonial .carousel-content {
  padding: 22px 30% 130px 0px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
#testimonial h3 {
  font-size: 18px;
  color: #1c2c40;
  margin-bottom: 0px;
  text-align: left;
  line-height: 28px;
  padding-left: 80px;
}
#testimonial h4 {
  font-size: 18px;
  color: #4f5b6a;
  margin-bottom: 30px;
  text-align: left;
  line-height: 28px;
  padding-left: 80px;
  font-weight: normal;
}
#testimonial p {
  font-size: 16px;
  line-height: 30px;
  color: #4f5b6a;
  padding-left: 80px;
  margin: 40px 0;
}
#testimonial .client-img img {
  width: 100%;
  max-width: 320px;
}
#testimonial .carousel-control-prev,
#testimonial .carousel-control-next {
  font-size: 36px;
}

@media (max-width: 576px) {
  .tutors {
    margin-top: 10px;
  }
  #testimonial .carousel-content {
    padding: 20px 0px 20px 0px;
    width: 100%;
  }
  #testimonial .client-img {
    margin: 20px auto;
    position: static;
  }
  #testimonial h3,
  #testimonial p {
    text-align: center;
    padding-left: 0px;
  }
}
/*-- End Testimonial Section CSS --*/
/*--------------------------------------------------------------
# Login Popup
--------------------------------------------------------------*/
#loginPopup .modal-dialog {
  border: 1px solid rgba(79, 91, 106, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(9, 40, 108, 0.05);
  border-radius: 8px;
}
#loginPopup .modal-header {
  justify-content: center;
  border: 0;
}
#loginPopup .modal-body {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
#loginPopup .modal-body .icon-box {
  text-align: center;
}
#loginPopup .modal-body .icon-box p {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #1c2c40;
  margin-bottom: 0px;
}
#loginPopup .modal-body .icon-box h4 {
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  color: #1c2c40;
}
#loginPopup .modal-footer {
  justify-content: center;
  border: 0;
  align-items: center;
}
#loginPopup .btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 100%;
  right: -12px;
  top: -12px;
  font-size: 12px;
  padding: 7px;
  position: absolute;
  z-index: 1;
}
/*--------------------------------------------------------------
# Signup Popup
--------------------------------------------------------------*/
#loginS .modal-dialog {
  border: 1px solid rgba(79, 91, 106, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(9, 40, 108, 0.05);
  border-radius: 8px;
}
#loginS .modal-content {
  padding: 40px;
}
#loginS .modal-header {
  justify-content: start;
  border: 0;
}
#loginS .modal-body {
  display: block;
}
#loginS .modal-body .title {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #1c2c40;
}
#loginS .modal-body .subtitle {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f5b6a;
}
#loginS .modal-body form input {
  background: #ffffff;
  border: 1px solid #e8e8e8;
  box-sizing: border-box;
  border-radius: 5px;
}
#loginS .modal-body form .label {
  font-weight: normal;
  text-transform: capitalize;
  margin-bottom: 11px;
  font-size: 16px;
  line-height: 19px;
  color: #4f5b6a;
}
#loginS .modal-body form .checkbox-wrap {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  color: #4f5b6a;
}
.checkbox-wrap {
  display: block;
  position: relative;
  padding-left: 30px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.checkbox-wrap input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkbox-wrap input[type="radio"] {
  position: absolute;
  visibility: hidden;
}
.checkbox-wrap .check {
  display: block;
  position: absolute;
  border: 1px solid #e8e8e8;
  background: #f7fafc;
  border-radius: 100%;
  height: 20px;
  width: 20px;
  top: 0px;
  left: 0px;
  z-index: 5;
  transition: border 0.25s linear;
  -webkit-transition: border 0.25s linear;
}

.checkbox-wrap:hover .check {
  border: 1px solid #e8e8e8;
}

.checkbox-wrap .check::before {
  display: block;
  position: absolute;
  content: "";
  border-radius: 100%;
  height: 12px;
  width: 12px;
  top: 3px;
  left: 3px;
  margin: auto;
  transition: background 0.25s linear;
  -webkit-transition: background 0.25s linear;
}

.checkbox-wrap input[type="radio"]:checked ~ .check {
  border: 1px solid #f1b31b;
}

.checkbox-wrap input[type="radio"]:checked ~ .check::before {
  background: #f1b31b;
}

#loginS .fget {
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: #0a40e9;
}
#loginS button {
  background-color: #09286c;
  box-shadow: 0px 15px 40px rgba(121, 159, 243, 0.4);
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
  padding-top: 15px;
  padding-bottom: 15px;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
#loginS .modal-footer {
  justify-content: center;
  border: 0;
  align-items: center;
}
#loginS .btn-close {
  background-color: #fff;
  opacity: 1;
  border-radius: 100%;
  right: -12px;
  top: -12px;
  font-size: 12px;
  padding: 7px;
  position: absolute;
  z-index: 1;
}
a.backToLogin {
  font-weight: normal;
  font-size: 14px;
  color: #0a40e9;
  display: flex;
  align-items: center;
  margin-top: 30px;
}
a.backToLogin i {
  font-weight: normal;
  font-size: 32px;
  color: #b2c5dc;
}
/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: #37517e;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid #37517e;
  border-top-color: #fff;
  border-bottom-color: #fff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  background: #47b2e4;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 24px;
  color: #fff;
  line-height: 0;
}
.back-to-top:hover {
  background: #6bc1e9;
  color: #fff;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  transition: all 0.5s;
  z-index: 997;
  padding: 15px 0;
}
#header.header-scrolled,
#header.header-inner-pages {
  background: #fff;
}
#header .logo {
  font-size: 30px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 500;
  letter-spacing: 2px;
  text-transform: uppercase;
}
#header .logo a {
  color: #fff;
}
#header .logo img {
  max-height: 40px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.navbar {
  padding: 0;
}
.navbar ul {
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
}
.navbar li {
  position: relative;
}
#header.header-inner-pages .navbar a,
#header.header-inner-pages .navbar a:focus {
  color: #1c2c40;
}
#header.header-inner-pages .navbar .getstarted,
#header.header-inner-pages .navbar .getstarted:focus {
  color: #fff;
}
.navbar a,
.navbar a:focus {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0 10px 30px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  white-space: nowrap;
  transition: 0.3s;
}
.navbar a i,
.navbar a:focus i {
  font-size: 12px;
  line-height: 0;
  margin-left: 5px;
}
.navbar a:hover,
.navbar .active,
.navbar .active:focus,
.navbar li:hover > a {
  color: #47b2e4;
}
.navbar .getstarted,
.navbar .getstarted:focus {
  padding: 8px 20px;
  margin-left: 30px;
  border-radius: 6px;
  color: #fff;
  font-size: 15px;
  border: 0;
  font-weight: 500;
  background-color: #09286c;
}
.navbar .getstarted:hover,
.navbar .getstarted:focus:hover {
  color: #fff;
  background: #31a9e1;
}
.navbar .dropdown ul {
  display: block;
  position: absolute;
  left: 14px;
  top: calc(100% + 30px);
  margin: 0;
  padding: 10px 0;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  transition: 0.3s;
  border-radius: 4px;
}
.navbar .dropdown ul li {
  min-width: 200px;
}
.navbar .dropdown ul a {
  padding: 10px 20px;
  font-size: 14px;
  text-transform: none;
  font-weight: 500;
  color: #0c3c53;
}
.navbar .dropdown ul a i {
  font-size: 12px;
}
.navbar .dropdown ul a:hover,
.navbar .dropdown ul .active:hover,
.navbar .dropdown ul li:hover > a {
  color: #47b2e4;
}
.navbar .dropdown:hover > ul {
  opacity: 1;
  top: 100%;
  visibility: visible;
}
.navbar .dropdown .dropdown ul {
  top: 0;
  left: calc(100% - 30px);
  visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
  opacity: 1;
  top: 0;
  left: 100%;
  visibility: visible;
}
@media (max-width: 1366px) {
  .navbar .dropdown .dropdown ul {
    left: -90%;
  }
  .navbar .dropdown .dropdown:hover > ul {
    left: -100%;
  }
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  color: #fff;
  font-size: 28px;
  cursor: pointer;
  display: none;
  line-height: 0;
  transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
  color: #fff;
}

@media (max-width: 991px) {
  .mobile-nav-toggle {
    display: block;
  }

  .navbar ul {
    display: none;
  }
}
.navbar-mobile {
  position: fixed;
  overflow: hidden;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(40, 58, 90, 0.9);
  transition: 0.3s;
  z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
  position: absolute;
  top: 15px;
  right: 15px;
}
.navbar-mobile ul {
  display: block;
  position: absolute;
  top: 55px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  padding: 10px 0;
  border-radius: 10px;
  background-color: #fff;
  overflow-y: auto;
  transition: 0.3s;
}
.navbar-mobile a,
.navbar-mobile a:focus {
  padding: 10px 20px;
  font-size: 15px;
  color: #37517e;
}
.navbar-mobile a:hover,
.navbar-mobile .active,
.navbar-mobile li:hover > a {
  color: #47b2e4;
}
.navbar-mobile .getstarted,
.navbar-mobile .getstarted:focus {
  margin: 15px;
  color: #37517e;
}
.navbar-mobile .dropdown ul {
  position: static;
  display: none;
  margin: 10px 20px;
  padding: 10px 0;
  z-index: 99;
  opacity: 1;
  visibility: visible;
  background: #fff;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
  min-width: 200px;
}
.navbar-mobile .dropdown ul a {
  padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
  font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover,
.navbar-mobile .dropdown ul .active:hover,
.navbar-mobile .dropdown ul li:hover > a {
  color: #47b2e4;
}
.navbar-mobile .dropdown > .dropdown-active {
  display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
.container {
  max-width: 1110px;
}
#hero {
  width: 100%;
  height: 100vh;
  /*background: #37517e;*/
  background: url("../img/daylearning-bg.jpg") no-repeat #f0f2ff;
  background-size: cover;
  overflow: visible;
}
/*#hero .container {
  padding-top: 72px;
}*/
#hero form {
  margin-top: 30px;
  background: #fff;
  padding: 10px 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 10px 50px rgba(9, 40, 108, 0.05);
  text-align: left;
  max-width: 560px;
}
#hero form input[type="email"]:focus-visible {
  outline: none;
}
#hero form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  font-weight: normal;
  font-size: 15px;
  width: calc(100% - 100px);
  color: #b2c5dc;
}
#hero form input[type="submit"] {
  width: auto;
  position: absolute;
  height: 45px;
  top: 3px;
  right: 3px;
  bottom: 0;
  border: 0;
  font-size: 15px;
  padding: 0 20px;
  background: #09286c;
  color: #fff;
  transition: 0.3s;
  box-shadow: 0px 20px 40px rgba(9, 40, 108, 0.2);
  border-radius: 4px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
}
#hero form input[type="submit"]:hover {
  background: #209dd8;
}
#hero h1 {
  margin: 0 0 10px 0;
  font-size: 40px;
  font-weight: bold;
  line-height: 49px;
  color: #ffffff;
}
#hero h2 {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 22px;
  font-weight: normal;
}
#hero .btn-get-started {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 28px 11px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px 0 0 0;
  color: #fff;
  background: #47b2e4;
}
#hero .btn-get-started:hover {
  background: #209dd8;
}
#hero .btn-watch-video {
  font-size: 16px;
  display: flex;
  align-items: center;
  transition: 0.5s;
  margin: 10px 0 0 25px;
  color: #fff;
  line-height: 1;
}
#hero .btn-watch-video i {
  line-height: 0;
  color: #fff;
  font-size: 32px;
  transition: 0.3s;
  margin-right: 8px;
}
#hero .btn-watch-video:hover i {
  color: #47b2e4;
}
#hero .animated {
  animation: up-down 2s ease-in-out infinite alternate-reverse both;
}
@media (max-width: 991px) {
  #hero {
    height: 100vh;
    text-align: center;
  }
  #hero .animated {
    -webkit-animation: none;
    animation: none;
  }
  #hero .hero-img {
    text-align: center;
  }
  #hero .hero-img img {
    width: 50%;
  }
}
@media (max-width: 768px) {
  #hero h1 {
    font-size: 28px;
    line-height: 36px;
  }
  #hero h2 {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 30px;
  }
  #hero .hero-img img {
    width: 70%;
  }
  .btn-area {
    display: flex;
    justify-content: space-between;
  }
  .list-item .btn-area .border-theme-btn {
    width: 45%;
  }
  .list-item .btn-area .theme-btn {
    width: 45%;
    margin-top: 0px;
  }
}
@media (max-width: 575px) {
  #hero .hero-img img {
    width: 80%;
  }
  #hero .btn-get-started {
    font-size: 16px;
    padding: 10px 24px 11px 24px;
  }
}

@-webkit-keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}

@keyframes up-down {
  0% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(-10px);
  }
}
/* ==========================================================================
   #FORM
   ========================================================================== */
input,
textarea {
  outline: none;
  margin: 0;
  border: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  width: 100%;
  font-size: 18px;
  font-family: inherit;
}

input:disabled {
  cursor: pointer;
  background: transparent;
}

textarea {
  resize: none;
}

button {
  cursor: pointer;
}

/* CHECKBOX */
/* Radio button */
.radio-row {
  padding-top: 10px;
}

.radio-container {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  font-size: 15px;
  color: #fff;
  font-weight: 700;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.radio-container:hover input ~ .radio-checkmark {
  background-color: #fff;
}

.radio-container input:checked ~ .radio-checkmark {
  background-color: #fff;
}

.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-container input:checked ~ .radio-checkmark:after {
  display: block;
}

.radio-container .radio-checkmark:after {
  top: 4px;
  left: 4px;
  width: 12px;
  height: 12px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
  background: #00ad5f;
}

.search-upper-section .input-group {
  position: relative;
  padding: 16px 20px;
  padding-bottom: 10px;
  background: #fff;
  border-right: 1px solid rgba(38, 44, 100, 0.15);
}
.search-upper-section .input-group:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.form::after {
  content: "";
  clear: both;
  display: table;
}

.input-icon {
  font-size: 24px;
  color: #808080;
  position: absolute;
  line-height: 60px;
  right: 20px;
  top: 0;
  width: 20px;
  background: #fff;
  text-align: center;
  cursor: pointer;
}

.input-icon::before {
  display: block;
  -webkit-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.label {
  font-size: 12px;
  color: #999;
  text-transform: uppercase;
  display: block;
  font-weight: 700;
}

.input--large {
  width: 300px;
}

.input--medium {
  width: 190px;
}

@media (max-width: 767px) {
  .input--large,
  .input--medium {
    width: 100%;
  }
}

.input--style-1 {
  font-size: 18px;
  padding: 9px 0;
}

.input--style-1::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
  color: #fff;
}

.input--style-1:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fff;
  opacity: 1;
}

.input--style-1::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fff;
  opacity: 1;
}

.input--style-1:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #fff;
}

.input--style-1:-ms-input-placeholder {
  /* Microsoft Edge */
  color: #fff;
}

.m-b-0 {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .m-b-0 {
    margin-bottom: 26px;
  }
}

.search-upper-section .input-group:last-child {
  border-right: 0px;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.search-upper-section {
  display: flex;
  background-color: #fff;
  border-radius: 8px;
}
.search-upper-section .input-group label {
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #9ea2a7;
}
.search-upper-section .input-group select {
  width: 100%;
  border: 0px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;
  color: #4f5b6a;
  padding-left: 0px;
}
.lower-section {
  margin-top: 5px;
}
.lower-section .input-group {
  margin-right: 5px;
}
.lower-section .input-group select,
.lower-section .input-group input {
  background: rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(28.082px);
  border-radius: 12px !important;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;

  /* identical to box height */
  display: flex;
  align-items: center;
  letter-spacing: 0.2px;

  color: #ffffff;
  border: 0px;
  padding: 14px 27px;
}
.lower-section .input-group input {
  padding: 14px 49px 14px 20px;
}
.lower-section .input-group .bi-search {
  position: absolute;
  color: #fff;
  right: 15px;
  z-index: 1;
  top: 12px;
}
#hero .advance-search {
  padding: 0px;
  margin-top: 0px;
  background: none;
}
#hero .card-body {
  padding: 0px;
}
#hero .card {
  background: none;
  border: 0px;
}
.filters h3 {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #1c2c40;
}
.sidebar .card {
  background: none;
}
.sidebar .media {
  position: relative;
}
.sidebar .content-area {
  position: absolute;
  bottom: 0;
  right: auto;
  text-align: center;
  left: auto;
  width: 100%;
}
.sidebar .content-area p {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  padding: 0 25px;
}
.sidebar .theme-btn {
  margin: 15px auto;
}
/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
  padding: 60px 0;
  /* overflow: hidden; */
}

.section-bg {
  background-color: #f3f5fa;
}

.section-title {
  text-align: left;
  padding-bottom: 30px;
}
.section-title h2 {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
  color: #1c2c40;
  line-height: 56px;
}
/*.section-title h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}*/
/*.section-title h2::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: #47b2e4;
  bottom: 0;
  left: calc(50% - 20px);
}*/
.section-title p {
  margin-bottom: 0;
}
.inner-page {
  background-color: #f9f9f9;
}
/*--------------------------------------------------------------
# Search Result Page
--------------------------------------------------------------*/
.list-item {
  background-color: #fff;
  padding: 15px 0;
  border-radius: 8px;
}
.list-item h2 {
  font-size: 22px;
  line-height: 27px;
  color: #1c2c40;
}
.list-item h2 a {
  color: #1c2c40;
}
.list-item .text-muted {
  font-size: 14px;
  line-height: 22px;
  color: #4f5b6a;
}
.list-item .category-link {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  text-transform: uppercase;
  color: #09286c;
}
.list-item .category-link span {
  color: #9ea2a7;
}
.list-item .category-link i {
  color: #f1b31b;
  font-size: 16px;
}
.list-item .theme-btn {
  width: 100%;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  height: 40px;
  padding: 0px;
  margin-top: 15px;
}
.border-theme-btn {
  border: 2px solid #09286c;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  color: #09286c;
}
.list-item .rating-area {
  margin-bottom: 70px;
  text-align: right;
}
.list-item .wishlist-link i {
  color: #838e9b;
  font-size: 20px;
  line-height: 17px;
}
.list-item .star-link i {
  color: #f5d71d;
  font-size: 20px;
  line-height: 17px;
}
.list-item .star-link span {
  font-size: 14px;
  color: #000000;
}
.pagination {
  justify-content: center;
}
.page-item.active .page-link {
  background: transparent;
  border: 1px solid #09286c;
  color: #09286c;
}
#faq h1,
#info h1 {
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  letter-spacing: 0.2px;
  color: #1c2c40;
  margin-bottom: 30px;
}
#info h3 {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #1c2c40;
  margin-bottom: 20px;
  margin-top: 50px;
}
#info .card.rate {
  min-height: auto;
}
#info .card.rate .card-text {
  margin-bottom: 0px;
}
#info .card {
  border: 0px;
  border-radius: 8px;
  box-shadow: 0px 20px 50px rgba(9, 40, 108, 0.05);
  min-height: 190px;
}
#info .card-img-top {
  margin-bottom: 20px;
}
#info .card-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #1c2c40;
}
#info .card-text {
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  color: #4f5b6a;
}
#faq .accordion-button:not(.collapsed) {
  background-color: #fff;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #18191f;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: none;
  padding-bottom: 0px;
}
#faq .accordion-body {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.2px;
  color: #4f5b6a;
}
#faq .accordion-collapse {
  background-color: #fff;
}
#faq .accordion-button {
  background-color: transparent;
}
#faq .accordion-item {
  border: 0px;
  background-color: transparent;
}
.accordion-button:not(.collapsed)::after {
  background-image: url(../img/down-circle.svg);
  transform: rotate(0deg);
}
.accordion-button::after {
  background-image: url(../img/right-circle.svg);
}
@media (max-width: 768px) {
  .cliens img {
    max-width: 40%;
  }
  .list-item .rating-area {
    margin-bottom: 18px;
    text-align: left;
  }
}
/*--------------------------------------------------------------
# Cliens
--------------------------------------------------------------*/
.cliens {
  padding: 12px 0;
  text-align: center;
}
.cliens img {
  max-width: 45%;
  transition: all 0.4s ease-in-out;
  display: inline-block;
  padding: 15px 0;
  filter: grayscale(100);
}
.cliens img:hover {
  filter: none;
  transform: scale(1.1);
}
@media (max-width: 768px) {
  .cliens img {
    max-width: 40%;
  }
}

/*--------------------------------------------------------------
# About Us
--------------------------------------------------------------*/
.about {
  background-color: #ffeebc;
}
.about .content h3 {
  font-weight: 600;
  font-size: 26px;
}
.about .content .accordion-list {
  padding-left: 0;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about.why-us .bg.img {
  transform: scale(1.4);
}
/*.about .content ul li {
  padding-left: 28px;
  position: relative;
}
.about .content ul li + li {
  margin-top: 10px;
}
.about .content ul i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 20px;
  color: #47b2e4;
  line-height: 1;
}*/
.about .content p:last-child {
  margin-bottom: 0;
}
.about .content .btn-learn-more {
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.3px;
  display: inline-block;
  padding: 12px 32px;
  background: #09286c;
  box-shadow: 8px 15px 30px rgba(9, 40, 108, 0.25);
  border-radius: 6px;
  transition: 0.3s;
  line-height: 1;
  color: #ffffff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  margin-top: 6px;
  width: max-content;
}
.about .content .btn-learn-more:hover {
  background: #09286c;
  color: #fff;
  text-decoration: none;
}

/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.theme-btn {
  background: #09286c;
  /* box-shadow: 0px 15px 40px rgba(121, 159, 243, 0.4); */
  border-radius: 6px;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: max-content;
  padding: 16px 38px;
  color: #ffffff;
  cursor: pointer;
}
.why-us .content {
  padding: 5px 40px 0 40px;
}
.why-us .content h3 {
  font-weight: bold;
  font-size: 40px;
  line-height: 56px;
  letter-spacing: 0.2px;
  color: #1c2c40;
}
.why-us .content h4 {
  font-size: 20px;
  font-weight: 700;
  margin-top: 5px;
}
.why-us .content p {
  font-size: 15px;
  color: #4f5b6a;
  line-height: 30px;
}
.why-us .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: top center;
}
.why-us .accordion-list {
  padding: 0 70px 0px 35px;
}
.why-us .accordion-list ul {
  padding: 0;
  list-style: none;
}
.why-us .accordion-list li + li {
  margin-top: 0px;
}
.why-us .accordion-list li {
  padding: 17px 0 0 40px;
  border-radius: 4px;
  position: relative;
}
/*.why-us .accordion-list a {
  display: block;
  position: relative;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-right: 30px;
  outline: none;
  cursor: pointer;
}*/
.why-us .accordion-list span {
  color: #47b2e4;
  font-weight: 600;
  font-size: 18px;
  padding-right: 10px;
}
.why-us .accordion-list strong {
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  letter-spacing: 0.2px;
  font-feature-settings: "salt" on, "liga" off;
  color: #1c2c40;
}
.why-us .accordion-list i {
  font-size: 16px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 22px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  border-radius: 100%;
  background: #ffc000;
}
.why-us .accordion-list p {
  margin-bottom: 0;
  padding: 5px 0 0 0;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  font-feature-settings: "salt" on, "liga" off;
  color: #4f5b6a;
}
.why-us .accordion-list .icon-show {
  display: none;
}
.why-us .accordion-list a.collapsed {
  color: #343a40;
}
.why-us .accordion-list a.collapsed:hover {
  color: #47b2e4;
}
.why-us .accordion-list a.collapsed .icon-show {
  display: inline-block;
}
.why-us .accordion-list a.collapsed .icon-close {
  display: none;
}
@media (max-width: 1024px) {
  .why-us .content,
  .why-us .accordion-list {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .why-us .img {
    min-height: 400px;
  }
  .why-us .content {
    padding-top: 30px;
  }
  .why-us .accordion-list {
    padding-bottom: 30px;
  }
  .about.why-us .bg.img {
    transform: scale(1);
  }
}
/*@media (max-width: 575px) {
  .why-us .img {
    min-height: 200px;
  }
}*/

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .content h3 {
  font-weight: 700;
  font-size: 32px;
  color: #37517e;
  font-family: "Poppins", sans-serif;
}
.skills .content ul {
  list-style: none;
  padding: 0;
}
.skills .content ul li {
  padding-bottom: 10px;
}
.skills .content ul i {
  font-size: 20px;
  padding-right: 4px;
  color: #47b2e4;
}
.skills .content p:last-child {
  margin-bottom: 0;
}
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 0;
  margin: 0 0 6px 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  color: #37517e;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: #e8edf5;
  height: 10px;
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: #4668a2;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
/*.services .icon-box {
  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
  padding: 50px 30px;
  transition: all ease-in-out 0.4s;
  background: #fff;
}*/
.services .row div:nth-child(2) .icon-box {
  background: url("../img/Line-Indicator-2.svg");
  background-repeat: no-repeat;
  background-position: right 30px;
}
.services .row div:nth-child(odd) .icon-box {
  background: url("../img/Line-Indicator.svg");
  background-repeat: no-repeat;
  background-position: right 30px;
}
.services .icon-box .icon {
  margin-bottom: 10px;
  width: 72px;
  height: 72px;
  box-shadow: 0px 20px 40px rgba(9, 40, 108, 0.1);
  border-radius: 100px;
  text-align: center;
  line-height: 72px;
}

.services .icon-box h4 {
  font-weight: 600;
  margin-top: 20px;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.2px;
  color: #1c2c40;
}
.services .icon-box h4 a {
  color: #1c2c40;
  transition: ease-in-out 0.3s;
}
.services .icon-box p {
  line-height: 30px;
  font-size: 16px;
  margin-bottom: 0;
  color: #4f5b6a;
}
.services .icon-box:hover {
  transform: translateY(-10px);
}
.services .icon-box:hover h4 a {
  color: #47b2e4;
}

/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
  background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)),
    url("../img/cta-bg.jpg") fixed center center;
  background-size: cover;
  padding: 120px 0;
}
.cta h3 {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
}
.cta p {
  color: #fff;
}
.cta .cta-btn {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 12px 40px;
  border-radius: 50px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #fff;
  color: #fff;
}
.cta .cta-btn:hover {
  background: #47b2e4;
  border: 2px solid #47b2e4;
}
@media (max-width: 1024px) {
  .cta {
    background-attachment: scroll;
  }
}
@media (min-width: 769px) {
  .cta .cta-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/*--------------------------------------------------------------
# Portfolio
--------------------------------------------------------------*/
.portfolio #portfolio-flters {
  list-style: none;
  margin-bottom: 20px;
}
.portfolio #portfolio-flters li {
  cursor: pointer;
  display: inline-block;
  margin: 10px 5px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1;
  color: #444444;
  transition: all 0.3s;
  padding: 8px 20px;
  border-radius: 50px;
  font-family: "Poppins", sans-serif;
}
.portfolio #portfolio-flters li:hover,
.portfolio #portfolio-flters li.filter-active {
  background: #47b2e4;
  color: #fff;
}
.portfolio .portfolio-item {
  margin-bottom: 30px;
}
.portfolio .portfolio-item .portfolio-img {
  overflow: hidden;
}
.portfolio .portfolio-item .portfolio-img img {
  transition: all 0.6s;
}
.portfolio .portfolio-item .portfolio-info {
  opacity: 0;
  position: absolute;
  left: 15px;
  bottom: 0;
  z-index: 3;
  right: 15px;
  transition: all 0.3s;
  background: rgba(55, 81, 126, 0.8);
  padding: 10px 15px;
}
.portfolio .portfolio-item .portfolio-info h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  color: #fff;
  margin-bottom: 0px;
}
.portfolio .portfolio-item .portfolio-info p {
  color: #f9fcfe;
  font-size: 14px;
  margin-bottom: 0;
}
.portfolio .portfolio-item .portfolio-info .preview-link,
.portfolio .portfolio-item .portfolio-info .details-link {
  position: absolute;
  right: 40px;
  font-size: 24px;
  top: calc(50% - 18px);
  color: #fff;
  transition: 0.3s;
}
.portfolio .portfolio-item .portfolio-info .preview-link:hover,
.portfolio .portfolio-item .portfolio-info .details-link:hover {
  color: #47b2e4;
}
.portfolio .portfolio-item .portfolio-info .details-link {
  right: 10px;
}
.portfolio .portfolio-item:hover .portfolio-img img {
  transform: scale(1.15);
}
.portfolio .portfolio-item:hover .portfolio-info {
  opacity: 1;
}

/*--------------------------------------------------------------
# Portfolio Details
--------------------------------------------------------------*/
.portfolio-details {
  padding-top: 40px;
}
.portfolio-details .portfolio-details-slider img {
  width: 100%;
}
.portfolio-details .portfolio-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: #fff;
  opacity: 1;
  border: 1px solid #47b2e4;
}
.portfolio-details
  .portfolio-details-slider
  .swiper-pagination
  .swiper-pagination-bullet-active {
  background-color: #47b2e4;
}
.portfolio-details .portfolio-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(55, 81, 126, 0.08);
}
.portfolio-details .portfolio-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.portfolio-details .portfolio-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.portfolio-details .portfolio-info ul li + li {
  margin-top: 10px;
}
.portfolio-details .portfolio-description {
  padding-top: 30px;
}
.portfolio-details .portfolio-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.portfolio-details .portfolio-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Team
--------------------------------------------------------------*/
.team .member {
  position: relative;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  transition: 0.5s;
}
.team .member .pic {
  overflow: hidden;
  width: 180px;
  border-radius: 50%;
}
.team .member .pic img {
  transition: ease-in-out 0.3s;
}
.team .member:hover {
  transform: translateY(-10px);
}
.team .member .member-info {
  padding-left: 30px;
}
.team .member h4 {
  font-weight: 700;
  margin-bottom: 5px;
  font-size: 20px;
  color: #37517e;
}
.team .member span {
  display: block;
  font-size: 15px;
  padding-bottom: 10px;
  position: relative;
  font-weight: 500;
}
.team .member span::after {
  content: "";
  position: absolute;
  display: block;
  width: 50px;
  height: 1px;
  background: #cbd6e9;
  bottom: 0;
  left: 0;
}
.team .member p {
  margin: 10px 0 0 0;
  font-size: 14px;
}
.team .member .social {
  margin-top: 12px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.team .member .social a {
  transition: ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  width: 32px;
  height: 32px;
  background: #eff2f8;
}
.team .member .social a i {
  color: #37517e;
  font-size: 16px;
  margin: 0 2px;
}
.team .member .social a:hover {
  background: #47b2e4;
}
.team .member .social a:hover i {
  color: #fff;
}
.team .member .social a + a {
  margin-left: 8px;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing .row {
  padding-top: 40px;
}
.pricing .box {
  padding: 60px 40px;
  box-shadow: 0 3px 20px -2px rgba(20, 45, 100, 0.1);
  background: #fff;
  height: 100%;
  border-top: 4px solid #fff;
  border-radius: 5px;
}
.pricing h3 {
  font-weight: 500;
  margin-bottom: 15px;
  font-size: 20px;
  color: #37517e;
}
.pricing h4 {
  font-size: 48px;
  color: #37517e;
  font-weight: 400;
  font-family: "Jost", sans-serif;
  margin-bottom: 25px;
}
.pricing h4 sup {
  font-size: 28px;
}
.pricing h4 span {
  color: #47b2e4;
  font-size: 18px;
  display: block;
}
.pricing ul {
  padding: 20px 0;
  list-style: none;
  color: #999;
  text-align: left;
  line-height: 20px;
}
.pricing ul li {
  padding: 10px 0 10px 30px;
  position: relative;
}
.pricing ul i {
  color: #28a745;
  font-size: 24px;
  position: absolute;
  left: 0;
  top: 6px;
}
.pricing ul .na {
  color: #ccc;
}
.pricing ul .na i {
  color: #ccc;
}
.pricing ul .na span {
  text-decoration: line-through;
}
.pricing .buy-btn {
  display: inline-block;
  padding: 12px 35px;
  border-radius: 50px;
  color: #47b2e4;
  transition: none;
  font-size: 16px;
  font-weight: 500;
  font-family: "Jost", sans-serif;
  transition: 0.3s;
  border: 1px solid #47b2e4;
}
.pricing .buy-btn:hover {
  background: #47b2e4;
  color: #fff;
}
.pricing .featured {
  border-top-color: #47b2e4;
}
.pricing .featured .buy-btn {
  background: #47b2e4;
  color: #fff;
}
.pricing .featured .buy-btn:hover {
  background: #23a3df;
}
@media (max-width: 992px) {
  .pricing .box {
    max-width: 60%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 767px) {
  .pricing .box {
    max-width: 80%;
    margin: 0 auto 30px auto;
  }
}
@media (max-width: 420px) {
  .pricing .box {
    max-width: 100%;
    margin: 0 auto 30px auto;
  }
}
/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.tutors {
  margin-top: -156px;
  padding-top: 0px;
}
.tutors .col-xl-3 {
  padding-left: 0px;
  padding-right: 0px;
}
.tutors .icon-box {
  /*  box-shadow: 0px 0 25px 0 rgba(0, 0, 0, 0.1);
*/
  padding: 20px;
  /*  transition: all ease-in-out 0.4s;
  background: #fff;*/
  width: 100%;
  border-right: 1px solid #e0f7ff;
  border-top: 1px solid #e0f7ff;
}
/*.tutors .icon-box:nth-child(4){
  border-right: 0px solid #E0F7FF;
}*/
.tutors .row div:nth-child(4) .icon-box {
  border-right: 0px solid #e0f7ff;
  border-top: 0px solid #e0f7ff;
}
.tutors .row div:nth-child(4n + 1) .icon-box {
  border-top: 0px solid #e0f7ff;
}
.tutors .row {
  background-color: #fff;
  filter: drop-shadow(0px 30px 60px rgba(9, 40, 108, 0.1));
  border-radius: 20px;
}
.tutors .icon-box .icon {
  margin-bottom: 10px;
  /*  width: 72px;
  height: 72px;
  box-shadow: 0px 20px 40px rgba(9, 40, 108, 0.1);
border-radius: 100px;
text-align: center;
    line-height: 72px;*/
}

.tutors .icon-box h4 {
  font-weight: 500;
  margin-top: 20px;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: #1c2c40;
}
.tutors .icon-box h4 a {
  color: #1c2c40;
}
.tutors .icon-box p {
  line-height: 17px;
  font-size: 14px;
  margin-bottom: 0;
  color: #4f5b6a;
  font-weight: normal;
}
.tutors .icon-box .count-area {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tutors .icon-box .count-area i {
  font-size: 40px;
  color: #b2c5dc;
  line-height: 40px;
}
@media (max-width: 576px) {
  .tutors {
    margin-top: 10px;
  }
}
/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq .faq-list {
  padding: 0 100px;
}
.faq .faq-list ul {
  padding: 0;
  list-style: none;
}
.faq .faq-list li + li {
  margin-top: 15px;
}
.faq .faq-list li {
  padding: 20px;
  background: #fff;
  border-radius: 4px;
  position: relative;
}
.faq .faq-list a {
  display: block;
  position: relative;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding: 0 30px;
  outline: none;
  cursor: pointer;
}
.faq .faq-list .icon-help {
  font-size: 24px;
  position: absolute;
  right: 0;
  left: 20px;
  color: #47b2e4;
}
.faq .faq-list .icon-show,
.faq .faq-list .icon-close {
  font-size: 24px;
  position: absolute;
  right: 0;
  top: 0;
}
.faq .faq-list p {
  margin-bottom: 0;
  padding: 10px 0 0 0;
}
.faq .faq-list .icon-show {
  display: none;
}
.faq .faq-list a.collapsed {
  color: #37517e;
  transition: 0.3s;
}
.faq .faq-list a.collapsed:hover {
  color: #47b2e4;
}
.faq .faq-list a.collapsed .icon-show {
  display: inline-block;
}
.faq .faq-list a.collapsed .icon-close {
  display: none;
}
@media (max-width: 1200px) {
  .faq .faq-list {
    padding: 0;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  width: 100%;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.1);
}
.contact .info i {
  font-size: 20px;
  color: #47b2e4;
  float: left;
  width: 44px;
  height: 44px;
  background: #e7f5fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #37517e;
}
.contact .info p {
  padding: 0 0 10px 60px;
  margin-bottom: 20px;
  font-size: 14px;
  color: #6182ba;
}
.contact .info .email p {
  padding-top: 5px;
}
.contact .info .social-links {
  padding-left: 60px;
}
.contact .info .social-links a {
  font-size: 18px;
  display: inline-block;
  background: #333;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
  margin-right: 10px;
}
.contact .info .social-links a:hover {
  background: #47b2e4;
  color: #fff;
}
.contact .info .email:hover i,
.contact .info .address:hover i,
.contact .info .phone:hover i {
  background: #47b2e4;
  color: #fff;
}
.contact .php-email-form {
  width: 100%;
  border-top: 3px solid #47b2e4;
  border-bottom: 3px solid #47b2e4;
  padding: 30px;
  background: #fff;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.12);
}
.contact .php-email-form .form-group {
  padding-bottom: 8px;
}
.contact .php-email-form .validate {
  display: none;
  color: red;
  margin: 0 0 15px 0;
  font-weight: 400;
  font-size: 13px;
}
.contact .php-email-form .error-message {
  display: none;
  color: #fff;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: #fff;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: #fff;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
.contact .php-email-form .form-group {
  margin-bottom: 20px;
}
.contact .php-email-form label {
  padding-bottom: 8px;
}
.contact .php-email-form input,
.contact .php-email-form textarea {
  border-radius: 0;
  box-shadow: none;
  font-size: 14px;
  border-radius: 4px;
}
.contact .php-email-form input:focus,
.contact .php-email-form textarea:focus {
  border-color: #47b2e4;
}
.contact .php-email-form input {
  height: 44px;
}
.contact .php-email-form textarea {
  padding: 10px 12px;
}
.contact .php-email-form button[type="submit"] {
  background: #47b2e4;
  border: 0;
  padding: 12px 34px;
  color: #fff;
  transition: 0.4s;
  border-radius: 50px;
}
.contact .php-email-form button[type="submit"]:hover {
  background: #209dd8;
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Breadcrumbs
--------------------------------------------------------------*/
.breadcrumbs {
  padding: 15px 0;
  background: #f3f5fa;
  min-height: 40px;
  margin-top: 72px;
}
@media (max-width: 992px) {
  .breadcrumbs {
    margin-top: 68px;
  }
}
.breadcrumbs h2 {
  font-size: 28px;
  font-weight: 600;
  color: #37517e;
}
.breadcrumbs ol {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 0 10px 0;
  margin: 0;
  font-size: 14px;
}
.breadcrumbs ol li + li {
  padding-left: 10px;
}
.breadcrumbs ol li + li::before {
  display: inline-block;
  padding-right: 10px;
  color: #4668a2;
  content: "/";
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  font-size: 14px;
}
#footer .footer-newsletter {
  padding: 70px 114px;
  background: url(../img/12.svg) no-repeat #ffe289;
  background-size: cover;
  background-position: bottom right;
  text-align: left;
  font-size: 15px;
  border-radius: 20px;
  color: #444444;
  margin-bottom: 136px;
}
#footer .footer-newsletter h4 {
  font-size: 40px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 54px;
  font-weight: bold;
  color: #1c2c40;
}
#footer .footer-newsletter form {
  margin-top: 30px;
  background: #fff;
  padding: 10px 10px;
  position: relative;
  border-radius: 8px;
  box-shadow: 0px 10px 50px rgba(9, 40, 108, 0.05);
  text-align: left;
}
#footer .footer-newsletter form input[type="email"] {
  border: 0;
  padding: 4px 8px;
  width: calc(100% - 100px);
}
#footer .footer-newsletter form input[type="submit"] {
  position: absolute;
  width: auto;
  top: 0;
  right: 0;
  bottom: 0;
  border: 0;
  font-size: 16px;
  padding: 0 20px;
  background: #09286c;
  color: #fff;
  transition: 0.3s;
  border-radius: 0px 8px 8px 0px;
  font-weight: 500;
  letter-spacing: 0.3px;
  color: #ffffff;
}
#footer .footer-newsletter form input[type="submit"]:hover {
  background: #209dd8;
}
#footer .footer-top {
  padding: 60px 0 30px 0;
  background: #000;
}
#footer .footer-top .footer-contact {
  margin-bottom: 30px;
}

#footer .footer-top .footer-contact h3 {
  font-size: 28px;
  margin: 0 0 38px 0;
  line-height: 1;
  text-transform: uppercase;
  font-weight: 600;
  color: #ffffff;
}
#footer .footer-top .footer-contact p {
  font-size: 14px;
  line-height: 26px;
  margin-bottom: 40px;
  color: #d9dbe1;
  font-weight: 400;
}
#footer .footer-top h4 {
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  position: relative;
  padding-bottom: 12px;
}
#footer .footer-top .footer-links {
  margin-bottom: 30px;
}
#footer .footer-top .footer-links ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
#footer .footer-top .footer-links ul i {
  padding-right: 2px;
  color: #47b2e4;
  font-size: 18px;
  line-height: 1;
}
#footer .footer-top .footer-links ul li {
  padding: 10px 0;
  display: flex;
  align-items: center;
}
#footer .footer-top .footer-links ul li:first-child {
  padding-top: 0;
}
#footer .footer-top .footer-links ul a {
  color: #d9dbe1;
  transition: 0.3s;
  display: inline-block;
  line-height: 26px;
  font-size: 16px;
}
#footer .footer-top .footer-links ul a:hover {
  text-decoration: none;
  color: #47b2e4;
}
#footer .footer-top .social-links a {
  font-size: 22px;
  display: inline-block;
  background: #23252e;
  color: #fff;
  line-height: 1;
  padding: 8px 0;
  margin-right: 4px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .footer-top .social-links a:hover {
  background: #209dd8;
  color: #fff;
  text-decoration: none;
}
#footer .footer-top .app-icons a {
  display: block;
  margin-top: 20px;
}
#footer .footer-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
  color: #fff;
}
#footer .copyright {
  float: left;
}
#footer .credits {
  float: right;
  font-size: 13px;
}
#footer .credits a {
  transition: 0.3s;
}
@media (max-width: 768px) {
  #footer .footer-bottom {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  #footer .copyright,
  #footer .credits {
    text-align: center;
    float: none;
  }
  #footer .credits {
    padding-top: 4px;
  }
  #footer .footer-newsletter {
    display: none;
  }
  section {
    padding: 20px 0;
  }
}

/*--------------------------------------------------------------
# Student Signup Section
--------------------------------------------------------------*/
.student-signup-page {
  padding: 200px 0;
}
.bg-inner {
  width: 100%;
  /*background: #37517e;*/
  background: url("../img/student-bg.jpg") no-repeat #f0f2ff;
  background-size: cover;
}
.student-signup-wrapper {
  background: #ffffff;
  border: 1px solid rgba(79, 91, 106, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(9, 40, 108, 0.05);
  border-radius: 8px;
  padding: 40px;
}
.student-form-header h3 {
  font-weight: bold;
  font-size: 30px;
  line-height: 36px;
  color: #1c2c40;
}
.student-form-header p {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f5b6a;
}
.student-signup-wrapper .signin-form label {
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #4f5b6a;
  margin-bottom: 10px;
  text-transform: capitalize;
}
.student-signup-wrapper .signin-form .terms {
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  color: #4f5b6a;
}
.student-signup-wrapper .signin-form .submit {
  background: #09286c;
  box-shadow: 0px 15px 40px rgba(121, 159, 243, 0.4);
  border-radius: 6px;
  font-weight: bold;
  font-size: 16px;
  line-height: 36px;
  align-items: center;
  text-align: center;
  color: #ffffff;
}
.signup-congrats .col-lg-6 {
  text-align: center;
  background: #ffffff;
  border: 1px solid rgba(79, 91, 106, 0.15);
  box-sizing: border-box;
  box-shadow: 0px 20px 50px rgba(9, 40, 108, 0.05);
  border-radius: 8px;
  padding: 40px;
}
.signup-congrats .col-lg-6 h3 {
  font-weight: bold;
  font-size: 24px;
  line-height: 40px;
  /* or 167% */
  text-align: center;
  letter-spacing: 0.2px;
  color: #1c2c40;
}
.signup-congrats .col-lg-6 p {
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  /* or 187% */
  text-align: center;
  color: #4f5b6a;
}
.signup-congrats .col-lg-6 a {
}
.signup-congrats .col-lg-6 .theme-btn {
  margin: 0 auto;
}
