.backdrop{
    height: 100vh;
    width: 100vw;
    position: fixed;
    background: black;
    opacity: 0.5;
    z-index: 1;
    top: 0;
}
.uploading_text{
    margin-top: 50vh;
    color: aliceblue;
    text-align: center;
}