.edit-btn {
  width: 100%;
  height: 45px;
  bottom: 0;
  border: 0;
  font-size: 15px;
  background: #09286c;
  color: #fff;
  transition: 0.3s;
  color: #ffffff;
}
.edit-btn:hover {
  background: #09286c;
}
.left-card {
  height: max-content;
  min-height: 518px;
}
.profile_pic {
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.edit_profile_icon {
  display: inline-block;
  background: white;
  width: 35px;
  height: 35px;
  padding: 5px;
  position: absolute;
  top: 144px !important;
  left: 56% !important;
  border-radius: 50%;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.demo_video {
  height: fit-content;
  padding: 10px;
  text-align: center;
  margin: auto;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
.video {
  vertical-align: middle;
}
.display_none {
  display: none !important;
}
.no_demo_video {
  padding: 20px;
}
.available_days_container {
  background-color: white;
  position: absolute;
  min-width: 355px;
  padding: 1rem;
  margin-top: 2px;
}

.display_inline {
  display: inline !important;
}

.custom_class_for_days {
  padding-left: 32px !important;
  margin-right: 22px !important;
}
.stepwizard-step p {
  margin-top: 0px;
  color: #4f5b6a;
  font-size: 15px;
  font-weight: 400;
}

.stepwizard-row {
  display: flex;
  justify-content: space-between;
}
.stepwizard {
  display: block;
  width: 100%;
  position: relative;
}

.stepwizard .btn.disabled,
.stepwizard .btn[disabled],
.stepwizard fieldset[disabled] .btn {
  opacity: 1 !important;
  color: #000000;
}
.stepwizard .btn-circle[disabled] {
  background: #ffffff;
  border: 2px solid #09286c;
}
.stepwizard-row:before {
  top: 20px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 95%;
  height: 2px;
  background-color: #09286c;
  z-index: 0;
  left: 14px;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 40px;
  height: 40px;
  text-align: center;
  padding: 6px 0;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.428571429;
  border-radius: 40px;
  background: #09286c;
}
.student-signup-page {
  padding-bottom: 50px !important;
}
.margin-left {
  margin-left: 10px;
}

.box {
  position: relative;
  background: #ffffff;
  width: 100%;
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  border-bottom: 1px solid #f4f4f4;
  margin-bottom: 10px;
}

.box-tools {
  position: absolute;
  right: 10px;
  top: 5px;
}

.dropzone-wrapper {
  border: 2px dashed #91b0b3;
  color: #92b0b3;
  position: relative;
  height: 150px;
}

.dropzone-desc {
  position: absolute;
  margin: 0 auto;
  left: 0;
  right: 0;
  text-align: center;
  width: 40%;
  top: 50px;
  font-size: 16px;
}

.dropzone,
.dropzone:focus {
  position: absolute;
  outline: none !important;
  width: 100%;
  height: 150px;
  cursor: pointer;
  opacity: 0;
}

.dropzone-wrapper:hover,
.dropzone-wrapper.dragover {
  background: #ecf0f5;
}

.preview-zone {
  text-align: center;
}

.preview-zone .box {
  box-shadow: none;
  border-radius: 0;
  margin-bottom: 0;
}
.profile-wrapper {
  background-color: white;
  padding: 10px;
}
#start-component .label {
  display: inline;
}
#end-component .label {
  display: inline;
}
.profile_spinner {
  justify-content: center;
  height: 100vh;
}
.btn-circle:hover {
  color: white;
}
